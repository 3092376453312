import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import * as S from "../components/Pages/general"

const NotFoundPage = () => (
  <Layout visibleSideBar={true}>
    <SEO
      title="404: Not found"
      description="Sentimos muito mas não encontramos a página solicitade, utilize os links abaixo para voltar ao início"
    />
    <S.GeneralWrapper>
      <S.GeneralTitle>Página não encontrada :(</S.GeneralTitle>
      <S.GeneralText>
        Sentimos muito mas nossos robos não encontraram a página solicitada.
        Caso você tenha certeza que seja um endereço correto, nos mande uma
        mensagem avisando do problema, do contrário, fique a vontade para voltar
        a home ou fazer uma busca em nosso site.
      </S.GeneralText>
      <S.GeneralButtonInner
        to="/"
        cover
        direction="left"
        bg="#08070e"
        duration={0.6}
      >
        HOME
      </S.GeneralButtonInner>
      <S.GeneralButtonInner
        to="/pesquisar"
        cover
        direction="left"
        bg="#08070e"
        duration={0.6}
      >
        PESQUISAR
      </S.GeneralButtonInner>
    </S.GeneralWrapper>
  </Layout>
)

export default NotFoundPage
